/**
 * Class PageLoader
 */
class PageLoader {
    static removeLoader() {
        let body = document.querySelector('body');
        let pageLoader = document.querySelector('.page-loader');

        if (!pageLoader) {
            return;
        }

        body.removeChild(pageLoader);
    }
}

export default PageLoader;